import { useBuildingContext } from '@common/context/useBuildingContext'
import { css } from '@emotion/react'
import { Dropdown, DropdownItem, DropdownItems } from '@design-system/components/dropdown'
import { NavigationBarItem as BaseNavigationBarItem } from '@design-system/components/navigation/desktop/components/navigationBarItem'
import { Skeleton } from '@design-system/components/skeleton'
import { EnterIcon, IconName } from '@design-system/enter-icons/enterIcon'
import { useTheme } from '@design-system/styles/theme'
import { StyleProps } from '@design-system/types/propTypes'
import { useCallback, useMemo, useState } from 'react'
import useGetAllBuildings from '@api/buildings/hooks/useGetAllBuildings'

export const BuildingNavigationBarItem = ({ styles }: StyleProps) => {
    const theme = useTheme()

    const [selectedBuildingUuid, setSelectedBuildingUuid] = useState<string>()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const { buildings: buildings } = useGetAllBuildings()

    const {
        buildingData: currentBuilding,
        isBuildingLoading: isCurrentBuildingLoading,
        handleBuildingChange,
    } = useBuildingContext()

    const makeHandleItemClick = useCallback(
        (buildingUuid: string, addressLabel?: string) => () => {
            setSelectedBuildingUuid(buildingUuid)

            handleBuildingChange(buildingUuid, addressLabel)
        },
        [handleBuildingChange]
    )

    const computedCurrentBuilding = useMemo(
        () =>
            buildings?.find((building) => {
                if (!currentBuilding || isCurrentBuildingLoading) {
                    return building.uuid === selectedBuildingUuid
                }

                return building.uuid === currentBuilding.uuid
            }),
        [buildings, currentBuilding, isCurrentBuildingLoading, selectedBuildingUuid]
    )

    const sortedAllBuildings = useMemo(() => {
        if (!buildings) return []

        return buildings.sort((a) => {
            if (!currentBuilding || isCurrentBuildingLoading) {
                return a.uuid === selectedBuildingUuid ? -1 : 1
            }

            return a.uuid === currentBuilding.uuid ? -1 : 1
        })
    }, [buildings, currentBuilding, isCurrentBuildingLoading, selectedBuildingUuid])

    if (!computedCurrentBuilding) {
        return <Skeleton width={'30%'} height={20} styles={styles} />
    }

    return (
        <Dropdown
            isOpen={isDropdownOpen}
            onOpenChange={setIsDropdownOpen}
            triggerProps={{
                styles: css`
                    display: flex;

                    ${styles}
                `,
                dataCy: 'building-switch-dropdown-trigger',
            }}
            contentProps={{
                styles: css`
                    width: var(--radix-dropdown-menu-trigger-width);
                    // default z-index is overwritten, because the dropdown is in the navigation component.
                    z-index: ${theme.zIndex.navigation};
                `,
            }}
            items={
                <DropdownItems>
                    {sortedAllBuildings?.map((building) => (
                        <DropdownItem
                            dataCy={`building-switch-dropdown-${building.uuid}`}
                            key={`building-dropdown-item-${building.uuid}`}
                            content={building.address.data.addressString}
                            handleClick={makeHandleItemClick(building.uuid, building.address.data.addressString)}
                        />
                    ))}
                </DropdownItems>
            }
        >
            <BaseNavigationBarItem
                iconName={IconName.GeneralHome}
                renderInlineEnd={
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            gap: ${theme.spacing(1)};
                        `}
                    >
                        <span>{computedCurrentBuilding.address.data.addressString}</span>

                        <EnterIcon
                            size="sm"
                            styles={css`
                                transform-origin: center;
                            `}
                            name={IconName.ChevronDown}
                            motionProps={{
                                initial: { rotate: 0 },
                                animate: { rotate: isDropdownOpen ? 180 : 0 },
                                transition: { duration: 0.2 },
                            }}
                        />
                    </div>
                }
            />
        </Dropdown>
    )
}
