import { useTheme } from '@design-system/styles/theme'
import { Text } from '@design-system/components/text'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import { OPACITY_TRANSITION } from '@design-system/styles/helpers'

export interface FooterProps {
    items: FooterItem[]
    height: number
    contentMaxWidth: string
    contentPadding: string
}

interface FooterItem {
    label: string
    href: string
}

export const Footer = ({ contentMaxWidth, contentPadding, height, items }: FooterProps) => (
    <motion.div
        css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: start;
            height: ${height}px;
        `}
        {...OPACITY_TRANSITION}
    >
        <div
            css={css`
                display: flex;
                width: 100%;
                justify-content: center;
                column-gap: 50px;
                padding: ${contentPadding};
                max-width: ${contentMaxWidth};
            `}
        >
            {items.map((footerItem, index) => (
                <FooterItem key={index} {...footerItem} />
            ))}
        </div>
    </motion.div>
)

const FooterItem = ({ label, href }: FooterItem) => {
    const theme = useTheme()

    return (
        <a href={href} target="_blank" rel="noreferrer">
            <Text
                styles={css`
                    text-decoration: underline;
                    font-weight: ${theme.fontWeight.headingDefault};
                `}
            >
                {label}
            </Text>
        </a>
    )
}
