import { css, SerializedStyles } from '@emotion/react'

import { Skeleton } from '../../../skeleton'
import { useTheme } from '../../../../styles/theme'

export function NavigationBarItemSkeleton({ styles }: { styles?: SerializedStyles }) {
    const theme = useTheme()
    return (
        <div
            css={[
                css`
                    display: flex;
                    align-items: center;
                    height: 100%;
                    gap: ${theme.spacing(1)};
                `,
                styles,
            ]}
        >
            <Skeleton height={theme.iconScale.md} width={theme.iconScale.md} />
            <Skeleton height="100%" width={100} />
        </div>
    )
}
