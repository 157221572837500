/* eslint-disable react/no-unknown-property */
import { css } from '@emotion/react'
import { useDesktop } from '../context/screenSizeContext'
import { poppins as bodyFont } from '../styles/fonts'

export type BaseLayoutProps = {
    renderToLayout: () => JSX.Element
    applicationBackgroundColor?: {
        mobile?: string
        desktop?: string
    }
    mobileApplicationBackgroundColor?: string
}

export default function BaseLayout({ renderToLayout, applicationBackgroundColor }: BaseLayoutProps) {
    const { isDesktop } = useDesktop()

    return (
        <div
            className={bodyFont.className}
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                min-height: 100vh;
                max-width: 100%;
            `}
        >
            {renderToLayout()}

            <style jsx global>{`
                html {
                    font-family: ${bodyFont.style.fontFamily};
                    background-color: ${isDesktop
                        ? applicationBackgroundColor?.desktop
                        : applicationBackgroundColor?.mobile};
                }
            `}</style>
        </div>
    )
}
