import {
    getBuildingsFromLocalStorage,
    getUserUuidFromLocalStorage,
    saveBuildingsInLocalStorage,
} from '@api/buildings/buildingsHelpers'
import useGetAllBuildings from '@api/buildings/hooks/useGetAllBuildings'
import { useGetBuilding } from '@api/buildings/hooks/useGetBuilding'
import { EnterError } from '@design-system/api/apiFactory'
import PageLoading from '@design-system/components/PageLoading/PageLoading'
import { sendToast } from '@design-system/components/toast'
import { GetBuildings } from '@design-system/types/buildings/buildingsTypes'
import { ChildrenProps } from '@design-system/types/propTypes'
import PageNotFound from '@pages/404'
import { UseQueryResult } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useRouter } from 'next/router'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

export type BuildingContextProviderProps = ChildrenProps
type BuildingContext = {
    handleBuildingChange: (newBuildingUuid: string, addressLabel?: string) => void
    buildingData: GetBuildings | undefined
    isBuildingLoading: boolean
}

export const BuildingContext = createContext<BuildingContext | null>(null)

export const BuildingContextProvider = ({ children }: BuildingContextProviderProps) => {
    const [globalBuildingUuid, setGlobalBuildingUuid] = useState<string>()
    const [lastFetchedBuilding, setLastFetchedBuilding] = useState<UseQueryResult<GetBuildings, EnterError>>()

    const router = useRouter()

    const uuidQueryParam = router.query.buildingUuid as string

    const shouldFetchAllBuildings = useMemo(
        () => !uuidQueryParam && !globalBuildingUuid,
        [uuidQueryParam, globalBuildingUuid]
    )

    const { buildings } = useGetAllBuildings(shouldFetchAllBuildings)
    const allBuildings = useMemo(() => buildings, [buildings])

    useEffect(() => {
        if (uuidQueryParam || allBuildings?.length) {
            const buildingToFetchUuid = uuidQueryParam || getBuildingsFromLocalStorage() || allBuildings?.[0]?.uuid
            setGlobalBuildingUuid(buildingToFetchUuid)
        }
    }, [uuidQueryParam, allBuildings])

    const buildingQueryResult = useGetBuilding({
        buildingUuid: globalBuildingUuid || '',
        enabled: !!globalBuildingUuid,
    })

    const isInitialFetch = useMemo(() => Boolean(!lastFetchedBuilding), [lastFetchedBuilding])

    const isBuildingLoading = useMemo(() => buildingQueryResult.isLoading, [buildingQueryResult.isLoading])

    const handleBuildingChange = useCallback(
        (newBuildingUuid?: string, addressLabel?: string) => {
            if (!newBuildingUuid) {
                return
            }

            setGlobalBuildingUuid(newBuildingUuid)
            saveBuildingsInLocalStorage({
                userUuid: getUserUuidFromLocalStorage(),
                buildingUuid: newBuildingUuid,
                addressLabel: addressLabel || '',
            })

            const shouldRedirect = Boolean(uuidQueryParam && uuidQueryParam !== newBuildingUuid)

            if (shouldRedirect) {
                router.replace(
                    {
                        query: {
                            ...router.query,
                            buildingUuid: newBuildingUuid,
                        },
                    },
                    {},
                    { scroll: false, shallow: true }
                )
            }
        },
        [router, uuidQueryParam]
    )

    const handleLastFetchedBuildingChange = useCallback(
        (newLastFetchedBuildingQueryResult: UseQueryResult<GetBuildings, EnterError> | undefined) => {
            setLastFetchedBuilding(newLastFetchedBuildingQueryResult)

            if (!newLastFetchedBuildingQueryResult || isInitialFetch) return

            const buildingName = newLastFetchedBuildingQueryResult?.data?.address?.data?.addressString || ''

            if (!buildingName) return

            sendToast({ message: `Sie befinden sich nun im Gebäude ${buildingName || ''}`, type: 'success' })
        },
        [isInitialFetch]
    )

    useEffect(() => {
        if (shouldFetchAllBuildings && allBuildings?.length) {
            handleBuildingChange(globalBuildingUuid || allBuildings?.[0]?.uuid)
        } else if (!globalBuildingUuid && uuidQueryParam) {
            handleBuildingChange(uuidQueryParam)
        }
    }, [allBuildings, globalBuildingUuid, handleBuildingChange, shouldFetchAllBuildings, uuidQueryParam])

    useEffect(() => {
        if (globalBuildingUuid) {
            ;(async () => {
                await buildingQueryResult.refetch()
            })()
        }
    }, [globalBuildingUuid])

    useEffect(() => {
        // If no building has been fetched yet or the building is loading, there is also no lastFetchedBuilding to be updated.
        if (!buildingQueryResult.data || buildingQueryResult.isLoading) return

        // If the building fetch failed, we reset the lastFetchedBuilding.
        if (buildingQueryResult.error) {
            handleLastFetchedBuildingChange(undefined)
        }

        // If:
        // 1. A building has been fetched, but we haven't yet saved it as the lastFetchedBuilding, or
        // 2. The current building doesn't equal the lastFetchedBuilding, we update the lastFetchedBuilding it.
        if (
            !lastFetchedBuilding ||
            JSON.stringify(lastFetchedBuilding.data) !== JSON.stringify(buildingQueryResult.data)
        ) {
            handleLastFetchedBuildingChange(buildingQueryResult)
        }
    }, [buildingQueryResult, handleBuildingChange, handleLastFetchedBuildingChange, lastFetchedBuilding])

    if (buildingQueryResult.error && isAxiosError(buildingQueryResult.error)) {
        const { error } = buildingQueryResult
        if (error.status === 404) {
            return <PageNotFound />
        }
    }

    if (isBuildingLoading || !buildingQueryResult.data) {
        return <PageLoading />
    }

    return (
        <BuildingContext.Provider
            value={{
                handleBuildingChange,
                buildingData: buildingQueryResult.data,
                isBuildingLoading,
            }}
        >
            {children}
        </BuildingContext.Provider>
    )
}
