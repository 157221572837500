import { Footer as BaseFooter, FooterProps as BaseFooterProps } from '@design-system/components/footer'
import { useMemo } from 'react'
import { External } from '@utils/urls'
import { CONTENT_BASE_PADDING } from '@common/types/form/types'

export const Footer = ({ height, contentMaxWidth }: { height: number; contentMaxWidth: string }) => {
    const footerItems = useMemo(
        () => [
            {
                label: 'Impressum',
                href: External.IMPRINT,
            },
            {
                label: 'Datenschutz',
                href: External.DATA_PRIVACY,
            },
        ],
        []
    )

    const footerProps: Omit<BaseFooterProps, 'height'> = useMemo(
        () => ({
            items: footerItems,
            contentMaxWidth: contentMaxWidth,
            contentPadding: `${CONTENT_BASE_PADDING}px`,
        }),
        [contentMaxWidth, footerItems]
    )

    return <BaseFooter height={height} {...footerProps} />
}
