import { SerializedStyles } from '@emotion/react'
import { EnterIcon, IconName } from '@design-system/enter-icons/enterIcon'
import { Button } from '@design-system/components/button/button'
import { ReactElement } from 'react'

export interface NavigationBarItemProps {
    iconName: IconName
    onClick?: () => void
    renderInlineEnd?: ReactElement
    styles?: SerializedStyles | SerializedStyles[]
    childrenStyles?: SerializedStyles | SerializedStyles[]
}

export const NavigationBarItem = ({
    styles,
    childrenStyles,
    iconName,
    onClick,
    renderInlineEnd,
}: NavigationBarItemProps) => {
    return (
        <Button
            styleType="inline-unstyled"
            buttonStyles={styles}
            onClick={onClick}
            childrenContainerStyles={childrenStyles}
        >
            <EnterIcon name={iconName} />
            {renderInlineEnd}
        </Button>
    )
}
