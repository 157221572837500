import { css } from '@emotion/react'
import { useTheme } from '@design-system/styles/theme'
import { motion } from 'framer-motion'
import { Button } from '@design-system/components/button/button'
import { EnterLogo } from '@design-system/components/enterLogo'

export interface DesktopNavigationBarProps {
    items: NavigationItem[]
    height: number
    contentMaxWidth: string
    contentPadding: string
    handleLogoClick: () => void
}

type NavigationItem = {
    renderItem: () => JSX.Element
}

export const DesktopNavigationBar = ({
    height,
    contentMaxWidth,
    contentPadding,
    items,
    handleLogoClick,
}: DesktopNavigationBarProps) => {
    // --- STATE ---

    const theme = useTheme()

    // --- RENDER ---

    return (
        <motion.div
            initial={{ opacity: 0, y: -height }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -height }}
            transition={{
                y: { duration: 0.4, type: 'spring' },
                opacity: { duration: 0.25, ease: 'easeOut' },
            }}
            css={css`
                width: 100%;
                min-height: ${height}px;
                padding: unset;
                display: flex;
                position: fixed;
                top: 0;
                align-items: center;
                justify-content: center;
                z-index: ${theme.zIndex.navigation};
                background-color: ${theme.colors.background.light};
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    width: 100%;
                    max-width: ${contentMaxWidth};
                    padding: ${contentPadding};
                `}
            >
                <Button
                    styleType="inline-unstyled"
                    onClick={handleLogoClick}
                    buttonStyles={css`
                        margin-right: auto;
                    `}
                >
                    <EnterLogo />
                </Button>

                {items.map((item, index) => (
                    <div
                        key={index}
                        css={css`
                            margin-right: ${theme.spacing(4)};

                            &:last-of-type {
                                margin-right: unset;
                            }
                        `}
                    >
                        {item.renderItem()}
                    </div>
                ))}
            </div>
        </motion.div>
    )
}
