import { datadogRum } from '@datadog/browser-rum'
import { EnterError, EnterQueryOptions } from '@design-system/api/apiFactory'
import { GetBuildings } from '@design-system/types/buildings/buildingsTypes'
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import { cookieName } from '@design-system/utils/firebaseAuthentication'

export const useGetBuilding = ({
    buildingUuid,
    enabled = true,
}: EnterQueryOptions<GetBuildings, QueryKey> & { buildingUuid: string }) => {
    const idToken = localStorage.getItem(cookieName)

    const bffQueryResult = useQuery({
        queryKey: ['getBuilding', buildingUuid],
        queryFn: async () => {
            const { data } = await axios
                .get<{
                    data: GetBuildings
                }>(`/api/buildings/${buildingUuid}/`, {
                    headers: {
                        'x-id-token': idToken,
                    },
                })
                .catch((error) => {
                    datadogRum.addError(error)
                    throw error
                })

            return data.data
        },
        enabled: !!buildingUuid && enabled,
        retry: 0,
        throwOnError: (error: any) => error.response?.status >= 500,
    })

    return bffQueryResult as UseQueryResult<GetBuildings, EnterError>
}
