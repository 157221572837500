import { ReactNode, useEffect } from 'react'
import { Page } from '@utils/urls'
import { useTrackCustomEvent } from '@common/hooks/useDataDog'
import { useTheme } from '@design-system/styles/theme'
import { css } from '@emotion/react'
import { Button } from '@design-system/components/button/button'
import { UserDefaultLoggedInLayout } from '@common/layouts/userDefaultLoggedInLayout'
import { AppShell } from '@common/layouts/appShell'
import { OneColumnPageLayout } from '@design-system/layouts/oneColumnPageLayout'

export default function PageNotFound() {
    const trackEvent = useTrackCustomEvent()

    useEffect(() => {
        trackEvent('404-page')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const theme = useTheme()

    return (
        <OneColumnPageLayout>
            <div
                css={css`
                    display: flex;
                    height: calc(100vh - 120px);
                    align-self: center;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    width: 100%;
                    > h1 {
                        font-size: 48px;
                        color: ${theme.colors.secondary.standard};
                        text-align: center;
                    }
                `}
            >
                <h1>
                    404
                    <br />
                    Seite nicht gefunden
                    <br />
                    <Button
                        styleType="secondary"
                        onClick={() => {
                            window.location.href = window.location.origin + Page.UNKNOWN_BUILDING_DASHBOARD
                        }}
                    >
                        Zurück zur Startseite
                    </Button>
                </h1>
            </div>
        </OneColumnPageLayout>
    )
}

PageNotFound.getLayout = (page: ReactNode) => (
    <UserDefaultLoggedInLayout>
        <AppShell>{page}</AppShell>
    </UserDefaultLoggedInLayout>
)
