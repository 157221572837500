import { SerializedStyles, css } from '@emotion/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContentProps, Portal } from '@radix-ui/react-dropdown-menu'
import { useTheme } from '../styles/theme'
import { omit } from '../utils/helpers'
import { sendGTMEvent } from '../utils/googleTagManager'
import { GTMDataLayerProps, GTMEvent, GTMSessionDataStorageKey, GTMValue } from '../types/GTMTypes'
import { useMemo } from 'react'

interface DropdownProps {
    children: React.ReactNode
    items: React.ReactNode
    onOpenChange?: (isOpen: boolean) => void
    isOpen?: boolean
    contentProps?: DropdownMenuContentProps & { styles: SerializedStyles }
    triggerProps?: DropdownMenu.DropdownMenuTriggerProps & { styles: SerializedStyles; dataCy?: string }
}

export const Dropdown = ({ children, items, onOpenChange, triggerProps, contentProps, isOpen }: DropdownProps) => {
    const theme = useTheme()

    return (
        <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger
                css={css`
                    all: unset;
                    cursor: pointer;

                    ${triggerProps?.styles}
                `}
                data-cy={triggerProps?.dataCy}
                {...(triggerProps ? omit(triggerProps, 'styles', 'dataCy') : {})}
            >
                {children}
            </DropdownMenu.Trigger>

            <Portal>
                <DropdownMenu.Content
                    sideOffset={5}
                    align="end"
                    css={css`
                        background: ${theme.colors.background.light};
                        border-radius: ${theme.borderRadius.dropdown};
                        z-index: ${theme.zIndex.dropdown};
                        box-shadow: 0px 3px 9px 0px #0000002e;
                        overflow: scroll;
                        max-height: 300px;
                        padding: ${theme.spacing(0.5)} 0px;

                        ${contentProps?.styles}
                    `}
                    {...(contentProps ? omit(contentProps, 'styles') : {})}
                >
                    {items}
                </DropdownMenu.Content>
            </Portal>
        </DropdownMenu.Root>
    )
}

interface DropdownItemsProps {
    children: React.ReactNode
}
export const DropdownItems = ({ children }: DropdownItemsProps) => (
    <div
        css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
        `}
    >
        {children}
    </div>
)

interface DropdownItemProps extends GTMDataLayerProps {
    icon?: React.ReactNode
    content: React.ReactNode
    handleClick: () => void
    dataCy?: string
}

export const DropdownItem = ({ icon, content, handleClick, dataCy, gtmValue, gtmData = {} }: DropdownItemProps) => {
    const theme = useTheme()

    const gtmSessionData = useMemo(() => {
        if (typeof window !== 'undefined') {
            const gtmSessionDataString = window?.sessionStorage?.getItem(GTMSessionDataStorageKey)
            if (gtmSessionDataString) return JSON.parse(gtmSessionDataString)
        }
    }, [])

    const onItemClick = () => {
        if (!handleClick) return

        if (gtmValue) {
            const GTMData = {
                ...(typeof gtmData === 'object' ? gtmData : {}),
                ...(gtmSessionData || {}),
            }
            sendGTMEvent(GTMEvent.UI_DROPDOWN_INTERACTION, gtmValue, GTMData)
        }

        handleClick()
    }

    return (
        <DropdownMenu.Item
            data-cy={dataCy}
            onClick={onItemClick}
            css={css`
                cursor: pointer;
                width: 100%;
                padding: 16px 14px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                font-size: 14px;

                &:hover {
                    background-color: ${theme.colors.secondary.light};
                    outline: none;
                }

                &[data-highlighted] {
                    outline: none;
                    background-color: ${theme.colors.secondary.light};
                }
            `}
        >
            {icon && (
                <div
                    css={css`
                        display: flex;
                        height: 100%;
                        align-items: center;
                    `}
                >
                    {icon}
                </div>
            )}

            <div>{content}</div>
        </DropdownMenu.Item>
    )
}
