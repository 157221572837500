import { useMe } from '@api/auth/hooks/useMe'
import { BuildingContextProvider, BuildingContextProviderProps } from '@common/context/buildingContext'
import { useEffect } from 'react'

type BuildingPageWrapperProps = BuildingContextProviderProps

export const UserDefaultLoggedInLayout = ({ children, ...restProviderProps }: BuildingPageWrapperProps) => {
    const { me } = useMe(false, true)

    useEffect(() => {
        if (!me) return

        setUpBraze({
            userUuid: me.uuid,
            userEmail: me.email,
        })
    }, [me])

    if (!me) return null

    return <BuildingContextProvider {...restProviderProps}>{children}</BuildingContextProvider>
}

function setUpBraze({ userUuid, userEmail }: { userUuid: string; userEmail: string }) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
        import('@braze/web-sdk').then(
            ({ initialize, openSession, changeUser, automaticallyShowInAppMessages, getUser, setLogger }) => {
                initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY as string, {
                    baseUrl: process.env.NEXT_PUBLIC_BRAZE_BASE_URL as string,
                    enableLogging: false,
                })
                setLogger(function () {
                    return
                })
                automaticallyShowInAppMessages()
                changeUser(userUuid)
                getUser()?.setEmail(userEmail)
                openSession()
            }
        )
    }
}
