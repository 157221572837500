import { css, SerializedStyles } from '@emotion/react'
import { motion, Variants } from 'framer-motion'
import { forwardRef, useId, useMemo } from 'react'
import useMeasure from 'react-use-measure'
import { useTheme } from '@design-system/styles/theme'
import { run, range } from '@design-system/utils/helpers'

export type ProgressBarBaseProps = {
    height?: number
    backgroundColor?: string
    containerStyles?: SerializedStyles
    progressBarContainerStyles?: SerializedStyles
}

export type ProgressBarStartType = 'left' | 'right'

export interface PointProgress {
    progress: number // in percentage.
    text: string
    label: string
    fillColor?: string
}

export type ProgressBarProps = ProgressBarBaseProps &
    (
        | {
              progress: number // in percentage.
              fillColor: string
              styleType: 'singleStep'

              start?: ProgressBarStartType
              progressBarFillStyles?: SerializedStyles
          }
        | {
              progress: number // in step units.
              fillColor: string
              styleType: 'multiStep'
              total: number

              start?: ProgressBarStartType
              progressBarFillStyles?: SerializedStyles
          }
        | {
              styleType: 'points'
              pointProgress: PointProgress[]
              pointClassDifference: number
              pointStyles?: SerializedStyles
          }
    )

const POINT_HEIGHT = 30

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
    ({ containerStyles, height = 3, backgroundColor, progressBarContainerStyles, ...restProps }, ref) => {
        // --- STATE ---s

        const componentId = useId()
        const theme = useTheme()

        const [progressBarRef, { width: progressBarWidth }] = useMeasure()

        // --- MEMOIZED ---

        const computedBackgroundColor: string = useMemo(
            () => backgroundColor || theme.palette.grey[0],
            [theme, backgroundColor]
        )

        // --- RENDER ---

        return (
            <div
                ref={ref}
                css={css`
                    width: 100%;
                    min-height: ${height}px;
                    position: relative;

                    ${restProps.styleType === 'multiStep' &&
                    css`
                        display: flex;
                        align-items: center;
                    `}

                    ${containerStyles}
                `}
            >
                {run(() => {
                    switch (restProps.styleType) {
                        case 'multiStep': {
                            const {
                                styleType,
                                progress,
                                total,
                                fillColor,
                                progressBarFillStyles,
                                start = 'left',
                            } = restProps

                            return (
                                <>
                                    {range(total).map((index) => (
                                        <div
                                            key={`progress-bar-${styleType}-${componentId}-${index}`}
                                            css={css`
                                                overflow: hidden;
                                                // This fixes a bug where on IOS inner element is overflowing.
                                                z-index: 1;
                                                width: calc(100% / ${total});
                                                margin-right: ${index + 1 === total ? 'unset' : theme.spacing(1)};
                                                background: ${computedBackgroundColor};
                                                height: ${height}px;
                                                border-radius: ${height}px;

                                                ${progressBarContainerStyles}
                                            `}
                                        >
                                            {index + 1 <= progress && (
                                                <motion.div
                                                    key={`progress-bar-fill-${styleType}-${componentId}-${index}`}
                                                    variants={getProgressBarAnimationVariants(
                                                        100,
                                                        start,
                                                        progress === index + 1
                                                    )}
                                                    initial="enter"
                                                    animate="animate"
                                                    css={css`
                                                        background: ${fillColor};
                                                        width: 100%;
                                                        min-height: ${height}px;

                                                        ${progressBarFillStyles}
                                                    `}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </>
                            )
                        }

                        case 'singleStep': {
                            const { styleType, fillColor, progress, progressBarFillStyles, start = 'left' } = restProps

                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        position: relative;
                                        z-index: 0;
                                        background: ${computedBackgroundColor};
                                        height: ${height}px;
                                        border-radius: ${height}px;

                                        ${progressBarContainerStyles}
                                    `}
                                >
                                    <motion.div
                                        key={`progress-bar-${styleType}-${componentId}`}
                                        variants={getProgressBarAnimationVariants(progress, start, true)}
                                        initial="enter"
                                        animate="animate"
                                        css={css`
                                            background: ${fillColor};
                                            width: 100%;
                                            min-height: ${height}px;
                                            border-radius: ${height}px;

                                            ${progressBarFillStyles}
                                        `}
                                    />
                                </div>
                            )
                        }

                        case 'points': {
                            const { styleType, pointProgress, pointStyles } = restProps

                            return (
                                <div
                                    ref={progressBarRef}
                                    css={css`
                                        position: relative;
                                        height: ${height}px;
                                        background: ${computedBackgroundColor};

                                        ${progressBarContainerStyles}
                                    `}
                                >
                                    {pointProgress.map((point, index) => (
                                        <motion.div
                                            key={`progress-bar-${styleType}-point-${componentId}-${index}`}
                                            variants={getPointsProgressBarAnimationVariants(
                                                point.progress,
                                                progressBarWidth
                                            )}
                                            initial="enter"
                                            animate="animate"
                                            css={css`
                                                position: absolute;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                height: ${POINT_HEIGHT}px;
                                                width: ${POINT_HEIGHT}px;
                                                top: ${-(POINT_HEIGHT / 2 - height / 2)}px;
                                                background: ${point.fillColor || theme.palette.grey[1]};
                                                border-radius: 6px;

                                                ${pointStyles}
                                            `}
                                        >
                                            <span
                                                css={css`
                                                    font-weight: 600;
                                                `}
                                            >
                                                {point.text}
                                            </span>

                                            <span
                                                css={css`
                                                    top: ${restProps.pointClassDifference < 2 && index === 1
                                                        ? -25
                                                        : POINT_HEIGHT + 10}px;
                                                    white-space: nowrap;
                                                    position: absolute;
                                                `}
                                            >
                                                {point.label}
                                            </span>
                                        </motion.div>
                                    ))}
                                </div>
                            )
                        }
                    }
                })}
            </div>
        )
    }
)

const getProgressBarAnimationVariants: (
    progressPercentage: number,
    start: ProgressBarStartType,
    shouldAnimate: boolean
) => Variants = (progressPercentage, start, shouldAnimate) => ({
    enter:
        (shouldAnimate && {
            x: start === 'left' ? '-100%' : '100%',
            transition: {
                x: { type: 'spring', duration: 2, stiffness: 300, damping: 100 },
            },
        }) ||
        {},
    animate:
        (shouldAnimate && {
            x: start === 'left' ? `${progressPercentage - 100}%` : `${100 - progressPercentage}%`,
            transition: {
                x: { type: 'spring', duration: 2, stiffness: 300, damping: 100 },
            },
        }) ||
        {},
})

const getPointsProgressBarAnimationVariants: (progressPercentage: number, width: number) => Variants = (
    progressPercentage,
    width
) => ({
    enter: {
        x: '-50%',
        transition: {
            x: { type: 'spring', duration: 2, stiffness: 300, damping: 100 },
        },
    },
    animate: {
        x: `calc(${(width * progressPercentage) / 100}px - 50%)`,
        transition: {
            x: { type: 'spring', duration: 2, stiffness: 300, damping: 100 },
        },
    },
})
