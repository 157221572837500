import { datadogRum } from '@datadog/browser-rum'
import { cookieName } from '@design-system/utils/firebaseAuthentication'
import { HandlerGetAllBuildingsResponseData } from '@pages/api/buildings/types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetAllBuildings = (enabled = true) => {
    const idToken = localStorage.getItem(cookieName)

    const {
        data: buildings,
        error: getBuildingsError,
        isLoading: isLoadingBuildings,
        isRefetching: isRefetchingBuildings,
        refetch: refetchBuildings,
    } = useQuery({
        queryKey: ['getAllBuildings'],
        queryFn: async () => {
            const { data: responseData } = await axios
                .get<HandlerGetAllBuildingsResponseData>('/api/buildings', {
                    headers: {
                        'x-id-token': idToken,
                    },
                })
                .catch((error) => {
                    datadogRum.addError(error)
                    throw error
                })

            return responseData.data
        },
        enabled,
        retry: 0,
        throwOnError: (error: any) => error.response?.status >= 500,
    })

    return {
        buildings,
        isLoadingBuildings,
        isRefetchingBuildings,
        refetchBuildings,
        getBuildingsError,
    }
}

export default useGetAllBuildings
