import { css } from '@emotion/react'

import { EnterTheme, useTheme, LayoutContentWidth } from '@design-system/styles/theme'
import { CONTENT_BASE_PADDING } from '@common/types/form/types'
import { NavigationBar } from '@common/components/navigation/navigationBar'
import { HeaderLogo } from './headerLogo'
import { useRouter } from 'next/router'
import { Page } from '@utils/urls'
import { useBuildingContext } from '@common/context/useBuildingContext'

export function DesktopHeader() {
    const theme = useTheme()
    const router = useRouter()
    const { buildingData: currentBuilding } = useBuildingContext()

    const buildingUuid = currentBuilding?.uuid || ''

    const onLogoClick = () => {
        if (router.asPath === Page.DASHBOARD({ buildingUuid })) return

        void router.push(Page.DASHBOARD({ buildingUuid: buildingUuid }))
    }

    return (
        <header css={getHeaderStyles(theme)}>
            <div css={getHeaderInnerStyles()}>
                <HeaderLogo onClick={onLogoClick} />
                <NavigationBar
                    type="desktop"
                    styles={css`
                        padding: 0 ${theme.spacing(3)};
                    `}
                />
            </div>
        </header>
    )
}

const getHeaderStyles = (theme: EnterTheme) => css`
    background-color: ${theme.colors.background.light};
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.header};
    height: 80px;
`

const getHeaderInnerStyles = () => css`
    max-width: ${LayoutContentWidth.WIDE};
    padding: 0 ${CONTENT_BASE_PADDING}px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
`
