import { useBuildingContext } from '@common/context/useBuildingContext'
import { CONTENT_BASE_PADDING } from '@common/types/form/types'
import { Page } from '@utils/urls'
import { NavigationBarItem as BaseNavigationBarItem } from '@design-system/components/navigation/desktop/components/navigationBarItem'
import { ProfileNavBarItem } from '@design-system/components/navigation/desktop/components/profileNavigationBarItem'
import {
    DesktopNavigationBar as BaseDesktopNavigationBar,
    DesktopNavigationBarProps as BaseDesktopNavigationBarProps,
} from '@design-system/components/navigation/desktop/desktopNavigationBar'
import { IconName } from '@design-system/enter-icons/enterIcon'
import { useRedirect } from '@design-system/utils/hooks'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { LayoutContentWidth } from '@design-system/styles/theme'
import { BuildingNavigationBarItem } from '@common/components/navigation/navigationBarItems/buildingNavigationBarItem'
import { FEATURES } from '@utils/authentication'
import { useMe } from '@api/auth/hooks/useMe'

export type DesktopNavigationBarProps = Pick<BaseDesktopNavigationBarProps, 'height'> & {
    visibleItems?: Partial<Record<NavigationItemKey, boolean>>
}

export enum NavigationItemKey {
    MultiBuildingSwitch = 'multiBuildingSwitch',
    DocumentSpace = 'documentSpace',
    Profile = 'profile',
}

const DEFAULT_VISIBLE_ITEMS: Record<NavigationItemKey, boolean> = {
    [NavigationItemKey.MultiBuildingSwitch]: FEATURES.multiBuilding,
    [NavigationItemKey.DocumentSpace]: true,
    [NavigationItemKey.Profile]: true,
}

export const DesktopNavigationBar = ({ height, visibleItems }: DesktopNavigationBarProps) => {
    const router = useRouter()
    const [makeHandleRedirect] = useRedirect()
    const { me } = useMe()
    const { buildingData: building } = useBuildingContext()

    const handleLogoClick = useCallback(() => {
        if (!building) return

        void router.push(Page.DASHBOARD({ buildingUuid: building.uuid }))
    }, [building, router])

    const navigationItems = useMemo(() => {
        if (!me) return []

        const mergedVisibleItems = {
            ...DEFAULT_VISIBLE_ITEMS,
            ...visibleItems,
        }

        return [
            ...(FEATURES.multiBuilding && mergedVisibleItems.multiBuildingSwitch
                ? [{ renderItem: () => <BuildingNavigationBarItem /> }]
                : []),

            ...(mergedVisibleItems.documentSpace
                ? [
                      {
                          renderItem: () => (
                              <BaseNavigationBarItem
                                  iconName={IconName.File05}
                                  onClick={makeHandleRedirect(
                                      Page.DOCUMENT_SPACE({
                                          buildingUuid: building?.uuid || '',
                                      })
                                  )}
                              />
                          ),
                      },
                  ]
                : []),

            ...(mergedVisibleItems.profile
                ? [{ renderItem: () => <ProfileNavBarItem redirectUrl={Page.PROFILE} user={me} /> }]
                : []),
        ].filter((item): item is BaseDesktopNavigationBarProps['items'][0] => Boolean(item))
    }, [building, makeHandleRedirect, me, visibleItems])

    const navigationProps = useMemo(
        () => ({
            handleLogoClick,
            contentMaxWidth: LayoutContentWidth.WIDE,
            contentPadding: `${CONTENT_BASE_PADDING}px`,
            items: navigationItems,
        }),
        [handleLogoClick, navigationItems]
    )

    return <BaseDesktopNavigationBar height={height} {...navigationProps} />
}
