import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import { IconName } from '@design-system/enter-icons/enterIcon'
import { EnterTheme, useTheme } from '@design-system/styles/theme'
import { css, SerializedStyles } from '@emotion/react'
import { useBuildingContext } from '@common/context/useBuildingContext'
import { Page } from '@utils/urls'
import { useDesktop } from '@design-system/context/screenSizeContext'
import { UserNavigationBarItem } from '@common/components/navigation/navigationBarItems/userNavigationBarItem'
import { NavigationBarItem } from '@design-system/components/navigation/desktop/components/navigationBarItem'

type NavigationType = 'mobile' | 'desktop'

export function NavigationBar({ type = 'desktop', styles }: { type: NavigationType; styles?: SerializedStyles }) {
    const router = useRouter()

    const theme = useTheme()
    const { desktopMediaQuery } = useDesktop()

    const { buildingData: currentBuilding } = useBuildingContext()

    const buildingUuid = currentBuilding?.uuid || ''

    const navItems = useMemo(
        () => [
            {
                href: Page.DASHBOARD({ buildingUuid }),
                content: 'Übersicht',
                iconName: IconName.HomeSmile,
            },
            {
                href: Page.SAVINGS_POTENTIAL({ buildingUuid }),
                content: 'Analyse',
                iconName: IconName.BarChartSquare02,
            },
            {
                href: Page.OFFER_OVERVIEW(buildingUuid),
                content: 'Angebote',
                iconName: IconName.TagIcon,
            },
            {
                href: Page.DOCUMENT_SPACE({ buildingUuid }),
                content: 'Dokumente',
                iconName: IconName.Folder,
            },
        ],
        [buildingUuid]
    )

    const navItemsStyles = useCallback(
        (href: string, content?: string) => {
            const isActive = Boolean(
                router.asPath === href || (router.asPath.includes('/check/heat-pump/') && content === 'Analyse')
            )
            const baseStyles = getNavItemStyles(theme, isActive)

            return {
                mobile: [baseStyles, getNavMobileItemStyles()],
                desktop: [baseStyles, getNavDesktopItemStyles()],
            }[type]
        },
        [router, theme, type]
    )

    if (!buildingUuid) return null

    return (
        <nav css={[getNavStyles(theme, desktopMediaQuery), styles]}>
            {navItems.map(({ href, content, iconName }) => (
                <NavigationBarItem
                    styles={getNavButtonStyles(theme, desktopMediaQuery)}
                    childrenStyles={navItemsStyles(href, content)}
                    key={href}
                    iconName={iconName}
                    onClick={async () => await router.push(href)}
                    renderInlineEnd={<div>{content}</div>}
                />
            ))}
            {type === 'desktop' && (
                <UserNavigationBarItem
                    childrenStyles={navItemsStyles(Page.PROFILE)}
                    styles={[
                        css`
                            margin-left: auto;
                        `,
                        getNavButtonStyles(theme, desktopMediaQuery),
                    ]}
                />
            )}
        </nav>
    )
}

const NAVIGATION_ITEMS_TRANSITION = 'all 100ms cubic-bezier(0.2, 0, 0.38, 0.9)'

const getNavStyles = (theme: EnterTheme, desktopMediaQuery: string) => css`
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: space-around;

    ${desktopMediaQuery} {
        justify-content: flex-start;
    }
`

const getNavButtonStyles = (theme: EnterTheme, desktopMediaQuery: string) => css`
    padding: 0 ${theme.spacing(1)};

    ${desktopMediaQuery} {
        padding: 0 ${theme.spacing(3)};
    }
`

const getNavItemStyles = (theme: EnterTheme, isActive: boolean) => css`
    height: 100%;
    letter-spacing: 0.1px;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: ${NAVIGATION_ITEMS_TRANSITION};
    gap: ${theme.spacing(1)};
    white-space: nowrap;

    &:hover {
        color: ${theme.colors.secondary.standard};
        transition: ${NAVIGATION_ITEMS_TRANSITION};
    }

    ${isActive ? `color: ${theme.colors.secondary.standard};` : null}
`

const getNavMobileItemStyles = () => css`
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
`

const getNavDesktopItemStyles = () => css`
    flex-direction: row;
    justify-content: flex-start;
`
