import { useCallback, useEffect } from 'react'
import { saveAndSendUserInformationToDataDog, trackEvent } from '@design-system/utils/dataDog'
import { useMe } from '@api/auth/hooks/useMe'

export const useSetDataDogUserSession = () => {
    const { me } = useMe()
    useEffect(() => {
        if (!me) return

        saveAndSendUserInformationToDataDog({
            id: me.uuid,
            name: me?.firstName + ' ' + me?.lastName,
            email: me.email,
        })
    }, [me])
}

export const useTrackCustomEvent = (predefinedProperties: Record<string, unknown> = {}) => {
    const { me } = useMe() || {}

    return useCallback(
        (title: string, properties: Record<string, unknown> = {}) => {
            trackEvent(title, {
                user: me,
                portal: 'customer',
                ...predefinedProperties,
                ...properties,
            })
        },
        [me, predefinedProperties]
    )
}
