const buildingLocalStorageName = 'enter-customer-buildings'

/**
 * Save user and building information in local storage.
 * @param {Object} params - Parameters for saving buildings.
 * @param {string} params.userUuid - User UUID.
 * @param {string} params.buildingUuid - Building UUID.
 */
export const saveBuildingsInLocalStorage = ({
    userUuid = '',
    buildingUuid = '',
    addressLabel = '',
}: {
    userUuid?: string
    buildingUuid?: string
    addressLabel?: string
}) => {
    localStorage.setItem(
        buildingLocalStorageName,
        JSON.stringify({
            userUuid,
            lastActiveBuildingUuid: buildingUuid,
            addressLabel,
        })
    )
}

/**
 * Get the last active building UUID from local storage based on the user UUID.
 * @param {string} uuid - User UUID.
 * @returns {string|undefined} - Last active building UUID or undefined if not found.
 */
export const getBuildingsFromLocalStorage = (): string | undefined => {
    const buildingData = localStorage.getItem(buildingLocalStorageName)
    if (!buildingData) return

    const { lastActiveBuildingUuid } = JSON.parse(buildingData) || {}

    return lastActiveBuildingUuid
}

export const getUserUuidFromLocalStorage = (): string | undefined => {
    const buildingData = localStorage.getItem(buildingLocalStorageName)
    if (!buildingData) return

    const { userUuid } = JSON.parse(buildingData) || {}

    return userUuid
}
