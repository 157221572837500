import { useContext } from 'react'
import { BuildingContext } from './buildingContext'

export const useBuildingContext = () => {
    const context = useContext(BuildingContext)

    if (context === null) {
        throw new Error('useBuildingContext must be used within a BuildingContextProvider')
    }

    return context
}
