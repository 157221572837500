import { NavigationBarItem } from '@design-system/components/navigation/desktop/components/navigationBarItem'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { IconName } from '@design-system/enter-icons/enterIcon'
import { NavigationBarItemSkeleton } from '@design-system/components/navigation/desktop/components/navigationBarItemSkeleton'

interface ProfileNavBarItemProps {
    user?: {
        firstName: string
        lastName: string
    }
    redirectUrl: string
}

export const ProfileNavBarItem = ({ user, redirectUrl }: ProfileNavBarItemProps) => {
    const router = useRouter()

    const handleRedirect = useCallback(() => {
        void router.push(redirectUrl)
    }, [router, redirectUrl])

    if (!user) return <NavigationBarItemSkeleton />

    return (
        <NavigationBarItem
            iconName={IconName.User01}
            onClick={handleRedirect}
            renderInlineEnd={<span>{`${user?.firstName} ${user?.lastName}`}</span>}
        />
    )
}
