import { ReactElement, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import { css, SerializedStyles } from '@emotion/react'
import { ChildrenProps } from '@design-system/types/propTypes'
import { useDesktop } from '@design-system/context/screenSizeContext'
import { EnterTheme, LayoutContentWidth, useTheme } from '@design-system/styles/theme'
import DefaultLoggedInLayout from '@design-system/layouts/defaultLoggedInLayout'
import { BaseLayoutProps } from '@design-system/layouts/baseLayout'
import { CONTENT_BASE_PADDING, CONTENT_DESKTOP_TOP_PADDING } from '@common/types/form/types'
import { Footer } from '@common/components/footer'
import { Header } from '@common/components/header'
import { LoadingIndicator } from '@design-system/components/loading/loadingIndicator'
import { useBuildingContext } from '@common/context/useBuildingContext'
import { DesktopNavigationBar } from '@common/components/navigation/desktopNavigationBar'
import { NavigationBar } from '@common/components/navigation/navigationBar'
import { Page } from '@utils/urls'
import { FEATURES } from '@utils/authentication'
import { ProgressBar } from '@design-system/components/progressBar'

type AppShellProps = ChildrenProps &
    Pick<BaseLayoutProps, 'applicationBackgroundColor'> & {
        contentMaxWidth?: LayoutContentWidth
        blockContentLoading?: boolean
        renderToHeader?: () => ReactElement | null
        renderToMobileNavigation?: () => ReactElement | null
        isContentLoading?: boolean
        progressBar?: {
            progress: number
            containerStyles?: SerializedStyles
        }
    }

export const AppShell = ({
    children,
    applicationBackgroundColor,
    contentMaxWidth = LayoutContentWidth.WIDE,
    renderToHeader,
    renderToMobileNavigation,
    isContentLoading = false,
    progressBar,
}: AppShellProps) => {
    const router = useRouter()
    const { desktopMediaQuery, isDesktop } = useDesktop()
    const theme = useTheme()

    const shouldRenderFooter = useMemo(() => Boolean(router.asPath === Page.PROFILE), [router])
    const shouldRenderHeader = useMemo(() => {
        const isNewHeaderAvailable = FEATURES.newNavigation || renderToHeader
        if (router.asPath === Page.PROFILE) {
            return isDesktop && isNewHeaderAvailable
        }
        return isNewHeaderAvailable
    }, [renderToHeader, router.asPath, isDesktop])

    const shouldRenderNavigationBar = !FEATURES.newNavigation

    const shouldRenderMobileNavigation =
        (!isDesktop && FEATURES.newNavigation) || (!isDesktop && renderToMobileNavigation)

    const { buildingData } = useBuildingContext()

    const makeRenderFooter = useCallback(() => {
        if (!shouldRenderFooter) return

        return ({ height }: { height: number }) => <Footer height={height} contentMaxWidth={contentMaxWidth} />
    }, [contentMaxWidth, shouldRenderFooter])

    const makeRenderHeader = useCallback(() => {
        if (!shouldRenderHeader) return
        if (renderToHeader) return renderToHeader()

        return <Header />
    }, [renderToHeader, shouldRenderHeader])

    const makeRenderNavigationBar = useCallback(() => {
        if (!shouldRenderNavigationBar) return

        return ({ height }: { height: number }) => <DesktopNavigationBar height={height} />
    }, [shouldRenderNavigationBar])

    const makeRenderMobileNavigation = useCallback(() => {
        if (!shouldRenderMobileNavigation) return

        if (renderToMobileNavigation) return renderToMobileNavigation()

        return <NavigationBar type="mobile" />
    }, [renderToMobileNavigation, shouldRenderMobileNavigation])

    return (
        <DefaultLoggedInLayout
            renderHeader={makeRenderHeader()}
            renderNavigationBar={makeRenderNavigationBar()}
            renderFooter={makeRenderFooter()}
            renderMobileNavigation={makeRenderMobileNavigation()}
            applicationBackgroundColor={{
                mobile: '#EDEDF2',
                desktop: '#EDEDF2',
                ...applicationBackgroundColor,
            }}
            renderToLayout={() =>
                isContentLoading ? (
                    <div css={getLoadingContainerStyles()}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <div css={getContainerStyles({ desktopMediaQuery })}>
                        {progressBar && (
                            <ProgressBar
                                height={isDesktop ? 8 : 4}
                                styleType="singleStep"
                                fillColor={theme.colors.secondary.standard}
                                backgroundColor={theme.colors.secondary.light}
                                containerStyles={getProgressBarStyles(theme)}
                                {...progressBar}
                            />
                        )}
                        <section
                            css={getInnerBaseStyles({
                                desktopMediaQuery,
                                contentMaxWidth,
                                hasBuilding: Boolean(buildingData),
                            })}
                        >
                            {children}
                        </section>
                    </div>
                )
            }
        />
    )
}

const getContainerStyles = ({ desktopMediaQuery }: { desktopMediaQuery: string }) => css`
    ${desktopMediaQuery} {
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
    }
`

const getInnerBaseStyles = ({
    desktopMediaQuery,
    contentMaxWidth,
    hasBuilding,
}: {
    desktopMediaQuery: string
    contentMaxWidth: string
    hasBuilding: boolean
}) => css`
    display: flex;
    flex-direction: column;
    padding: ${CONTENT_BASE_PADDING}px;

    ${desktopMediaQuery} {
        padding-top: ${CONTENT_DESKTOP_TOP_PADDING}px;
        max-width: ${contentMaxWidth};
        width: 100%;
    }

    ${!hasBuilding &&
    css`
        justify-content: center;
    `}
`

const getLoadingContainerStyles = () => css`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

const getProgressBarStyles = (theme: EnterTheme) => css`
    position: sticky;
    width: 100%;
    top: ${FEATURES.newNavigation ? '80px' : '100px'};
    z-index: ${theme.zIndex.header};
`
