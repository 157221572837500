import { css } from '@emotion/react'
import { useMemo, ReactElement } from 'react'
import BaseLayout, { BaseLayoutProps } from '@design-system/layouts/baseLayout'
import { useTheme } from '../styles/theme'

export type DefaultLoggedInLayoutProps = Omit<BaseLayoutProps, 'renderToLayout'> & {
    renderToLayout: (args: { remainingViewportCssHeight: string }) => ReactElement
    renderNavigationBar?: (args: { remainingViewportCssHeight: string; height: number }) => ReactElement
    renderFooter?: (args: { remainingViewportCssHeight: string; height: number }) => ReactElement
    renderHeader?: ReactElement | null
    renderMobileNavigation?: ReactElement | null
}

export const NAVIGATION_BAR_HEIGHT = 106
export const FOOTER_HEIGHT = 60
export const MOBILE_NAVIGATION_BAR_HEIGHT = 80

export default function DefaultLoggedInLayout({
    renderToLayout,
    renderFooter,
    renderNavigationBar,
    renderHeader,
    renderMobileNavigation,
    ...baseLayoutProps
}: DefaultLoggedInLayoutProps) {
    const theme = useTheme()
    const computedRemainingViewportCssHeight = useMemo(() => {
        if (renderFooter && renderNavigationBar) return `calc(100vh - ${NAVIGATION_BAR_HEIGHT + FOOTER_HEIGHT}px)`

        if (renderNavigationBar) return `calc(100vh - ${NAVIGATION_BAR_HEIGHT}px)`

        if (renderFooter) return `calc(100vh - ${FOOTER_HEIGHT}px)`

        return '100vh'
    }, [renderFooter, renderNavigationBar])

    return (
        <BaseLayout
            renderToLayout={() => (
                <>
                    {renderHeader}
                    {renderNavigationBar?.({
                        height: NAVIGATION_BAR_HEIGHT,
                        remainingViewportCssHeight: computedRemainingViewportCssHeight,
                    })}

                    <div
                        css={css`
                            width: 100%;
                            height: 100%;
                            flex: 1;
                            position: relative;
                            ${renderMobileNavigation &&
                            css`
                                padding-bottom: ${MOBILE_NAVIGATION_BAR_HEIGHT}px;
                            `}
                            ${renderNavigationBar &&
                            css`
                                margin-top: ${NAVIGATION_BAR_HEIGHT}px;
                            `}
                        `}
                    >
                        {renderToLayout({
                            remainingViewportCssHeight: computedRemainingViewportCssHeight,
                        })}
                    </div>

                    {renderFooter?.({
                        height: FOOTER_HEIGHT,
                        remainingViewportCssHeight: computedRemainingViewportCssHeight,
                    })}
                    {renderMobileNavigation && (
                        <div
                            css={css`
                                position: fixed;
                                bottom: 0;
                                height: ${MOBILE_NAVIGATION_BAR_HEIGHT}px;
                                width: 100%;
                                background-color: ${theme.colors.background.light};
                                border-radius: 18px 18px 0 0;
                                box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.18);
                                padding: ${theme.spacing(2)} ${theme.spacing(1)};
                            `}
                        >
                            {renderMobileNavigation}
                        </div>
                    )}
                </>
            )}
            {...baseLayoutProps}
        />
    )
}
