import { css } from '@emotion/react'
import Image from 'next/image'
import { StyleProps } from '@design-system/types/propTypes'

export function HeaderLogo({
    styles,
    onClick,
}: StyleProps & {
    onClick?: () => void
}) {
    return (
        <div
            css={[
                css`
                    width: 100px;
                    cursor: ${onClick ? 'pointer' : 'default'};
                `,
                styles,
            ]}
            onClick={onClick}
        >
            <Image
                id="enter-header-logo"
                priority
                src="/enter-logo-gradient.png"
                alt="Enter"
                height={100}
                width={200}
                quality={100}
                css={css`
                    height: auto;
                    width: 100%;
                `}
            />
        </div>
    )
}
