import { useRouter } from 'next/router'
import { css } from '@emotion/react'

import {
    NavigationBarItem,
    NavigationBarItemProps,
} from '@design-system/components/navigation/desktop/components/navigationBarItem'
import { IconName } from '@design-system/enter-icons/enterIcon'
import { Page } from '@utils/urls'
import { useBuildingContext } from '@common/context/useBuildingContext'
import Text from '@design-system/components/text/text'
import { useTheme } from '@design-system/styles/theme'
import Skeleton from 'react-loading-skeleton'
import { useMe } from '@api/auth/hooks/useMe'

export function UserNavigationBarItem({
    styles,
    childrenStyles,
}: Pick<NavigationBarItemProps, 'childrenStyles' | 'styles'>) {
    const router = useRouter()
    const theme = useTheme()
    const { me, isMeLoading } = useMe() || {}

    const { buildingData: building, isBuildingLoading } = useBuildingContext()
    const { address } = building || {}

    return (
        <NavigationBarItem
            styles={styles}
            childrenStyles={childrenStyles}
            iconName={IconName.User01}
            onClick={async () => await router.push(Page.PROFILE)}
            renderInlineEnd={
                <div
                    css={css`
                        text-align: left;
                    `}
                >
                    <Text
                        variant="label"
                        size="large"
                        as="div"
                        css={css`
                            color: inherit;
                        `}
                    >
                        {!isMeLoading && `Hallo, ${me?.firstName}!`}
                        {isMeLoading && <Skeleton />}
                    </Text>
                    <Text
                        variant="label"
                        size="small"
                        css={css`
                            color: ${theme.colors.text.secondary.onLight};
                        `}
                    >
                        {!isBuildingLoading && address?.data?.addressString}
                        {isBuildingLoading && <Skeleton />}
                    </Text>
                </div>
            }
        />
    )
}
