import { css } from '@emotion/react'
import { EnterTheme, useTheme, LayoutContentWidth } from '@design-system/styles/theme'
import { CONTENT_BASE_PADDING } from '@common/types/form/types'
import { UserNavigationBarItem } from '@common/components/navigation/navigationBarItems/userNavigationBarItem'
import { HeaderLogo } from '@common/components/header/headerLogo'

export function MobileHeader() {
    const theme = useTheme()

    return (
        <header css={getHeaderStyles(theme)}>
            <div css={getHeaderInnerStyles(theme)}>
                <HeaderLogo />
                <UserNavigationBarItem />
            </div>
        </header>
    )
}

const getHeaderStyles = (theme: EnterTheme) => css`
    z-index: ${theme.zIndex.header};
    height: 80px;
`

const getHeaderInnerStyles = (theme: EnterTheme) => css`
    max-width: ${LayoutContentWidth.WIDE};
    padding: ${theme.spacing(4)} ${CONTENT_BASE_PADDING}px 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
